"use client";

import Image from "next/image";

import Bali from "@/public/png/home/popular-destinations/bali.png";
import Dubai from "@/public/png/home/popular-destinations/dubai.png";
import Maldives from "@/public/png/home/popular-destinations/maldives.png";
import Singapore from "@/public/png/home/popular-destinations/singapore.png";
import Thailand from "@/public/png/home/popular-destinations/thailand.png";
import Malaysia from "@/public/png/home/popular-destinations/malaysia.png";

import Andaman from "@/public/png/home/popular-destinations/andaman.jpg";
import Srilanka from "@/public/png/home/popular-destinations/srilanka.jpg";
import Vietnam from "@/public/png/home/popular-destinations/vietnam.jpg";
import Hongkong from "@/public/png/home/popular-destinations/hong-kong.png";
import Azerbaijan from "@/public/png/home/popular-destinations/azerbaijan.png";


import PaginationLeft from "@/public/svg/paginarition-arrow-left.svg";
import PaginationRight from "@/public/svg/paginarition-arrow-right.svg";
import Redirect from "@/public/svg/redirect.svg";

import Title from "@/components/title/Title";

import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as TSwiper } from "swiper/types";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "@/styles/common/swiper.scss";

import "./popular-destinations.scss";

import { useState } from "react";
import Link from "next/link";

const Destinations = [
    {
        name: "Thailand",
        picture: Thailand,
        note: "Starting from ₹18,000",
        path: "thailand",
    },
    
    {
        name: "Bali",
        picture: Bali,
        note: "Starting from ₹25,000",
        path: "bali",
    },
    {
        name: "Maldives",
        picture: Maldives,
        note: "Starting from ₹41,000",
        path: "maldives",
    },
    {
        name: "Singapore",
        picture: Singapore,
        note: "Starting from ₹40,000",
        path: "singapore",
    },
    {
        name: "Malaysia",
        picture: Malaysia,
        note: "Starting from ₹29,000",
        path: "malaysia",
    },
    {
        name: "Dubai",
        picture: Dubai,
        note: "Starting from ₹45,000",
        path: "dubai",
    },
    {
        name: "Hongkong",
        picture: Hongkong,
        note: "Starting from ₹55,499",
        path: "hongkong",
    },
    {
        name: "Andaman",
        picture: Andaman,
        note: "Starting from ₹16,000",
        path: "andaman",
    },
    {
        name: "Srilanka",
        picture: Srilanka,
        note: "Starting from ₹23,000",
        path: "srilanka",
    },
    {
        name: "Vietnam",
        picture: Vietnam,
        note: "Starting from ₹18,000",
        path: "vietnam",
    },
    {
        name: "Azerbaijan",
        picture: Azerbaijan,
        note: "Starting from ₹45,000",
        path: "azerbaijan",
    },
];

const ExtendedDest = [...Destinations, ...Destinations];

const PopularDestinations = () => {
    const [activeSlide, setActiveSlide] = useState(1);
    const totalSlides = Destinations.length;

    const setPagination = (e: TSwiper) => {
        setActiveSlide((e.realIndex % totalSlides) + 1);
    };

    return (
        <section
            className="popular-destinations-section py-10 md:py-20"
            id="popularDestinations"
        >
            <div className="popular-destinations-container container px-4 flex flex-col mx-auto gap-6 sm:gap-14">
                <Title title="Popular Destinations" />
                <div className="popular-destinations-slider">
                    <Swiper
                        spaceBetween={24}
                        slidesPerView={1}
                        navigation={{
                            nextEl: ".pagination-right",
                            prevEl: ".pagination-left",
                            enabled: true,
                        }}
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: 3000,
                            waitForTransition: true,
                        }}
                        loop={true}
                        breakpoints={{
                            639: {
                                slidesPerView: 2,
                            },
                            767: {
                                slidesPerView: 3,
                            },
                            1023: {
                                slidesPerView: 4,
                            },
                            1279: {
                                slidesPerView: 5,
                            },
                        }}
                        className="destinations-swiper"
                        onSlideChange={setPagination}
                        onSwiper={setPagination}
                    >
                        {ExtendedDest.map((d, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="destinations-slide"
                                >
                                    <Link
                                        href={d.path}
                                        className="inline-flex flex-col gap-6"
                                    >
                                        <figure>
                                            <Image loading="lazy"
                                                className="picture"
                                                src={d.picture}
                                                sizes="(max-width:768px) 100vw, (max-width: 1200px) 25vw, 20vw"
                                                alt={d.name}
                                                
                                                
                                            />
                                            <Image loading="lazy"
                                                src={Redirect}
                                                alt="Redirect"
                                                className="redirect"
                                                width={36}
                                                
                                                height={36}
                                                
                                            />
                                            <figcaption className="font-yesteryear font-extralight flex-center text-center">
                                                {d.name}
                                            </figcaption>
                                        </figure>
                                        <span className="font-light text-center">
                                            {d.note}
                                        </span>
                                    </Link>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div className="swiper-pagination-wrapper flex items-center justify-end gap-2 mt-4 sm:mt-10">
                        <div className="pagination-progress flex items-center gap-4">
                            <span className="font-semibold">
                                {activeSlide}/{totalSlides}
                            </span>
                            <div className="progress">
                                <div
                                    className="done"
                                    style={{
                                        width: `${
                                            ((activeSlide - 1) % totalSlides) *
                                            (100 / totalSlides)
                                        }%`,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="pagination-arrows flex items-center gap-3 sm:gap-4">
                            <figure className="pagination-left flex-center">
                                <Image loading="lazy"
                                    src={PaginationLeft}
                                    alt="Pagination Left"
                                    
                                />
                            </figure>
                            <figure className="pagination-right flex-center">
                                <Image loading="lazy"
                                    src={PaginationRight}
                                    alt="Pagination Right"
                                    
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PopularDestinations;
